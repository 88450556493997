if (window.hljs) {
  hljs.registerLanguage('unigine',
      function(a) {
        var b = {
          keyword: 'false int float while private char catch export virtual operator sizeof dynamic_cast|10 typedef ' +
              'const_cast|10 const struct for static_cast|10 union namespace unsigned long throw volatile static protected ' +
              'bool template mutable if public friend do return goto auto void enum else break new extern using true class ' +
              'asm case typeid short reinterpret_cast|10 default double register explicit signed typename try this switch ' +
              'continue wchar_t inline delete alignof char16_t char32_t constexpr decltype noexcept nullptr static_assert ' +
              'thread_local restrict _Bool complex _Complex _Imaginary' +
              'const void int long float double vec3 vec4 dvec3 ' +
              'dvec4 ivec3 ivec4 mat4 quat string struct namespace enum vec3_zero vec4_one forloop foreach foreachkey yield ' +
              'wait call thread',
          built_in: 'std string cin cout cerr clog stringstream istringstream ostringstream auto_ptr deque list queue ' +
              'stack vector map set bitset multiset multimap unordered_set unordered_map unordered_multiset unordered_multimap ' +
              'array shared_ptr abort abs acos asin atan2 atan calloc ceil cosh cos exit exp fabs floor fmod fprintf fputs free ' +
              'frexp fscanf isalnum isalpha iscntrl isdigit isgraph islower isprint ispunct isspace isupper isxdigit tolower ' +
              'toupper labs ldexp log10 log malloc memchr memcmp memcpy memset modf pow printf putchar puts scanf sinh sin ' +
              'snprintf sprintf sqrt sscanf strcat strchr strcmp strcpy strcspn strlen strncat strncmp strncpy strpbrk strrchr ' +
              'strspn strstr tanh tan vfprintf vprintf vsprintf',
        };
        return {
          k: b,
          i: '</',
          c: [
            a.CLCM,
            a.CBLCLM,
            a.QSM,
            {cN: 'string', b: '\'\\\\?.', e: '\'', i: '.'},
            {
              cN: 'number',
              b: '\\b(\\d+(\\.\\d*)?|\\.\\d+)(u|U|l|L|ul|UL|f|F)',
            },
            a.CNM,
            {
              cN: 'preprocessor',
              b: '#',
              e: '$',
              c: [{b: 'include\\s*<', e: '>', i: '\\n'}, a.CLCM],
            },
            {
              cN: 'stl_container',
              b: '\\b(deque|list|queue|stack|vector|map|set|bitset|multiset|multimap|unordered_map|unordered_set|unordered_multiset|unordered_multimap|array)\\s*<',
              e: '>',
              k: b,
              r: 10,
              c: ['self'],
            }],
        };
      }
  );

  hljs.registerLanguage('output',
      function(a) {
        var b = {cN: 'variable', b: /\$\(/, e: /\)/, c: [a.BE]};
        return {
          c: [
            a.HCM,
            {
              b: /^\w+\s*\W*=/,
              rB: true,
              r: 0,
              starts: {
                cN: 'constant',
                e: /\s*\W*=/,
                eE: true,
                starts: {e: /$/, r: 0, c: [b]},
              },
            },
            {cN: 'title', b: /^[\w]+:\s*$/},
            {cN: 'phony', b: /^\.PHONY:/, e: /$/, k: '.PHONY', l: /[\.\w]+/},
            {
              b: /^\t+/,
              e: /$/,
              c: [a.QSM, b],
            }],
        };
      }
  );

  hljs.registerLanguage('shader',
      function(a) {
        var b = {
          keyword: 'int2 int3 int4 uint2 uint3 uint4 float float2 float3 float4 float2x2 float3x3 float4x4 TYPE_R ' +
              'TYPE_RG TYPE_RGB TYPE_RGBA FLOAT FLOAT2 FLOAT3 FLOAT4 INT INT2 INT3 INT4 BOOL2 BOOL3 BOOL4 float_isrgb ' +
              'float4_zero float4_one float4_neg_one float4_isrgb float3_zero float3_one float3_neg_one float3_up ' +
              'float3_isrgb float2_zero float2_one float2_neg_one float2_isrgb int4_zero int4_one int4_neg_one ' +
              'int3_zero int3_one int3_neg_one int2_zero int2_one int2_neg_one PI PI2 PI05 LOG2 LOG10 SQRT2 EPSILON ' +
              'INFINITY DEG2RAD RAD2DEG STATICARRAY MAIN_VOID MAIN_VOID_END STATICVAR STATICARRAY ENDARRAY ' +
              'BLEND_ALPHABLEND BLEND_ADDITIVE BLEND_MULTIPLICATIVE BLEND_NONE forloop MODIFER_LINEAR MODIFER_CENTROID ' +
              'MODIFER_NOINTERPOLATION MODIFER_NOPERSPECTIVE MODIFER_SAMPLE STRUCT END CBUFFER EXPORT_SHADER ' +
              'INIT_TEXTURE INIT_TEXTURE_CUBE INIT_TEXTURE_3D INIT_TEXTURE_ARRAY INIT_TEXTURE_SHADOW ' +
              'INIT_TEXTURE_ARRAY_SHADOW INIT_TEXTURE_CUBE_SHADOW INIT_RW_TEXTURE TEXTURE TEXTURE_BIAS ' +
              'TEXTURE_OFFSET_BIAS TEXTURE_OFFSET TEXTURE_GRAD TEXTURE_LOAD_LOD TEXTURE_LOAD TEXTURE_SHADOW ' +
              'TEXTURE_SHADOW_PROJ TEXTURE_CUBE_SHADOW TEXTURE_2D_CUBIC TEXTURE_RW_LOAD TEXTURE_RW_STORE ' +
              'TEXTURE_BIAS_ZERO TEXTURE_ARRAY TEXTURE_TRIPLANAR TEXTURE_OUT TEXTURE_OUT_2 TEXTURE_OUT_3 ' +
              'TEXTURE_OUT_4 TEXTURE_IN TEXTURE_IN_2 TEXTURE_IN_3 TEXTURE_IN_4 TEXTURE_IN_CUBE TEXTURE_IN_3D ' +
              'TEXTURE_IN_ARRAY TEXTURE_IN_SHADOW TEXTURE_IN_ARRAY_SHADOW TEXTURE_IN_CUBE_SHADOW INIT_ATTRIBUTE ' +
              'INIT_OUT INIT_POSITION INIT_INSTANCE IN_INSTANCE IN_ATTRIBUTE OUT_DATA OUT_POSITION INIT_IN INIT_COLOR ' +
              'INIT_DEPTH INIT_MRT INIT_FRONTFACE IN_POSITION IN_DATA IN_FRONTFACE OUT_COLOR OUT_DEPTH OUT_MRT ' +
              'INIT_GEOM_IN INIT_GEOM_OUT IN_GEOM_DATA IN_GEOM_POSITION OUT_GEOM_DATA TRIANGLE_IN TRIANGLE_OUT ' +
              'LINE_IN LINE_OUT INIT_DATA GET_DATA IF_DATA ENDIF INIT_CONTROL_IN INIT_CONTROL_OUT OUT_PATCH_EDGE ' +
              'OUT_CONTROL_DATA IN_CONTROL_DATA OUT_CONTROL_POSITION IN_CONTROL_POSITION CONTROL_POINT_ID ' +
              'OUT_PATCH_INSIDE INIT_EVALUATE_IN INIT_EVALUATE_OUT OUT_EVALUATE_DATA IN_EVALUATE_PATCH_DATA ' +
              'OUT_EVALUATE_POSITION IN_EVALUATE_PATCH_POSITION DOMAIN_LOCATION MAIN_PATCH_CONSTANT_BEGIN ' +
              'END_PATCH_CONSTANT MAIN_CONTROL_BEGIN END_CONTROL MAIN_EVALUATE_BEGIN END_EVALUATE struct ' +
              'INIT_CUSTOM_DEPTH_VERTEX_OUT PERFORM_DEPTH_VERTEX_OUT' +
              'atomic_uint attribute bool break bvec2 bvec3 bvec4 case centroid coherent const continue default ' +
              'discard dmat2 dmat2x2 dmat2x3 dmat2x4 dmat3 dmat3x2 dmat3x3 dmat3x4 dmat4 dmat4x2 dmat4x3 ' +
              'dmat4x4 do double dvec2 dvec3 dvec4 else flat float for highp if iimage1D iimage1DArray ' +
              'iimage2D iimage2DArray iimage2DMS iimage2DMSArray iimage2DRect iimage3D iimageBuffer iimageCube ' +
              'iimageCubeArray image1D image1DArray image2D image2DArray image2DMS image2DMSArray image2DRect ' +
              'image3D imageBuffer imageCube imageCubeArray in inout int invariant isampler1D isampler1DArray ' +
              'isampler2D isampler2DArray isampler2DMS isampler2DMSArray isampler2DRect isampler3D isamplerBuffer ' +
              'isamplerCube isamplerCubeArray ivec2 ivec3 ivec4 layout lowp mat2 mat2x2 mat2x3 mat2x4 mat3 mat3x2 ' +
              'mat3x3 mat3x4 mat4 mat4x2 mat4x3 mat4x4 mediump noperspective out patch precision readonly restrict ' +
              'return sample sampler1D sampler1DArray sampler1DArrayShadow sampler1DShadow sampler2D sampler2DArray ' +
              'sampler2DArrayShadow sampler2DMS sampler2DMSArray sampler2DRect sampler2DRectShadow sampler2DShadow ' +
              'sampler3D samplerBuffer samplerCube samplerCubeArray samplerCubeArrayShadow samplerCubeShadow smooth ' +
              'struct subroutine switch uimage1D uimage1DArray uimage2D uimage2DArray uimage2DMS uimage2DMSArray ' +
              'uimage2DRect uimage3D uimageBuffer uimageCube uimageCubeArray uint uniform usampler1D usampler1DArray ' +
              'usampler2D usampler2DArray usampler2DMS usampler2DMSArray usampler2DRect usampler3D usamplerBuffer ' +
              'usamplerCube usamplerCubeArray uvec2 uvec3 uvec4 varying vec2 vec3 vec4 void volatile while writeonly',
          built_in: 'branch call flatten ifAll ifAny isolate loop maxexports maxInstructionCount maxtempreg ' +
              'noExpressionOptimizations predicate predicateBlock reduceTempRegUsage removeUnusedInputs sampreg ' +
              'unroll unused xps earlydepthstencil overlay fmod frac lerp lerpOne length2 getBasisX getBasisX ' +
              'getBasisY getBasisY getBasisZ getBasisZ rsqrt saturate ddx ddy rcp equal greaterThan atan2 any max2 ' +
              'max3 max4 min2 min3 min4 pow2 powMirror srgb srgbInv nrand nrand2 nrandTiled nrandTiled nrand2Tiled ' +
              'nrandTemporal nrandTemporal nrand2Temporal nrandTAA nrandTAA nrand2TAA dotFixed lerpFixed getPosition ' +
              'getDepthToPosition getLinearizedDepth getViewDirection getPositionToUV getAnimationOffset ' +
              'getTangentBasis uvTransform checkUV normalizationTBN mul mul3 mul4 floatPack88To16 floatPack16To88 ' +
              'floatPack8888To1616 floatPack1616To8888 floatPack1212To888 floatPack888To1212 floatPack44To8 ' +
              'floatPack8To44 loadEnvironmentCubeMap getEnvironmentReflectVector environmentReflectionShading ' +
              'gbufferSRGB getFresnelSchlick getFresnel getGGX getGGX getAreaLightGGX getPhong getBlinn getBurley12 ' +
              'getWrapAround getTranslucent getSpecularBRDF getAreaLightSpecularBRDF getDiffuseBRDF getBRDF ' +
              'getLightAttenuation sphereLightToLight capsuleLightToLight getMicrofiber specularReflection ' +
              'hazeScattering hazeForward hazeAlpha hazeSolid hazeForwardSimple length',
          literal: 'true false',
        };

        return {
          aliases: ['glsl'],
          k: b,
          c: [
            a.CLCM, a.CBLCLM, a.CNM, {
              cN: 'preprocessor',
              b: '#',
              e: '$',
              c: [{b: 'include\\s*<', e: '>', i: '\\n'}, a.CLCM],
            }],
        };
      }
  );
}